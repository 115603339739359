import React from 'react'
import HomeLayout from '@layouts/HomeLayout'
import { Helmet } from 'react-helmet'
import qs from 'query-string'

const IndexPage = props => {
  /**
     this page is for referal and other tracking ONLY 
     typecally the clients don't see it!
     do not reuse it for normal flows!
   **/

  const { location } = props
  let { sku, price, orderId, currency, totalDiscount } = qs.parse(location.search)
  
  if (!Array.isArray(sku)) sku = [sku]
  if (!Array.isArray(price)) price = [price]

  React.useEffect(() => {

    // facebook pixel
    fbq('track', 'Purchase')

    // refersion
    if (window._refersion && sku) {
      window.sendRefersion = function() {
        // step 2, report sale
        // set-up the order
        window._refersion(function() {

          window._rfsn._addTrans({
            order_id: orderId,
            currency_code: currency,
            discount: totalDiscount / 100 + ''
          })

          sku.map((sku, idx) => {
            window._rfsn._addItem({
              sku: sku,
              quantity: '1',
              // the example had string here...
              // stripe uses cents, sounds like the better idea
              price: price[idx] / 100 + '',
            })
          })

          //send
          window._rfsn._sendConversion()

          console.log('refersion data sent', sku, price)
        })
      }

      window.sendRefersion()
    } else {
      console.warn('refersion not loaded, most likely due to adblock!')
    }

    if (window.gtag) {
      // -------- google analytics -------------
      window.gtag('event', 'purchase', {
        transaction_id: orderId,
        // "affiliation": "Google online store",
        value: price / 100,
        currency: currency,
        //"tax": 1.24,
        //"shipping": 0,
        items: [
          {
            id: sku,
            name: 'Facebook Bible', // TODO
            quantity: 1,
            price: price / 100,
          },
        ],
      })
    }
  }, [])

  return (
    <HomeLayout>
      <Helmet>
        <script src='https://www.refersion.com/tracker/v3/pub_0a5444e1e727e07c847a.js' />
      </Helmet>
      <div className='content'>
        <br />
        <h1>Thank you for your purchase!</h1>
      </div>
    </HomeLayout>
  )
}

export default IndexPage
